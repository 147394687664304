import { useEffect, useState } from "preact/hooks";

export default function FileViewer() {
  const [files, setFiles] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>("");

  useEffect(() => {
    async function fetchFiles() {
      const res = await fetch("/api/list-results");
      if (res.ok) {
        const data = await res.json();
        setFiles(data);
      } else {
        console.error("Failed to fetch files");
      }
    }

    fetchFiles();
  }, []);

  const handleFileSelect = (file: string) => {
    setSelectedFile(file);
  };

  return (
    <div class="bg-gray-100 p-4 border rounded">
      <h2 class="text-xl font-bold mb-1">Test Result Files</h2>
      <ul class="list-disc pl-5 h-40 overflow-auto bg-white p-2 border rounded">
        {files.length > 0
          ? (
            files.map((file) => (
              <li key={file}>
                <button
                  onClick={() => handleFileSelect(file)}
                  class={`text-blue-500 underline ${
                    selectedFile === file ? "font-bold" : ""
                  }`}
                >
                  {file}
                </button>
              </li>
            ))
          )
          : <li>No result files available.</li>}
      </ul>

      {selectedFile && (
        <div class="mt-4">
          <p class="font-bold">Selected File: {selectedFile}</p>
          <a
            href={`/api/download/${selectedFile}`}
            class="bg-blue-500 text-white px-4 py-2 mt-2 inline-block"
            download
          >
            Download Selected File
          </a>
        </div>
      )}
    </div>
  );
}
